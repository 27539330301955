import { memo, useCallback, useEffect } from "react";
import isEqual from "react-fast-compare";
import { PlacementType } from "./labelComponent";
import Player from "components/dataLabeling/player/Player";
import moment from "moment";
import { DATE_FORMAT, TIME_FORMAT } from "components/dataLabeling/constants";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "store";
import actions, { loadBufferSilent } from "store/machineDetail/sounds/actions";
import { createSelector } from "reselect";
import { useTranslation } from "react-i18next";
import PlacementLoading from "./placementLoading";
import Box from "@mui/material/Box";
import soundsActions from "store/machineDetail/sounds/actions";
import { useNavigate } from "react-router-dom";

type TProps = {
  placement: PlacementType;
  navigate: any;
  selected?: boolean;
  addDateActive?: boolean;
  setQuery: any;
};

type CombinedState = {
  state: AppState;
  placement: number;
};

const selector = createSelector(
  ({ state, placement }: CombinedState) =>
    state.machineDetail.sounds.placements[placement],
  ({ state }: CombinedState) => state.machineDetail.sounds,
  (placement, sounds) => {
    return {
      isPlaying: sounds.isPlaying,
      buffer: placement?.buffer ?? {},
      loadingBuffer: placement?.loadingBuffer ?? true,
      duration: sounds.duration,
      timezoneOffset: sounds.timezoneOffset,
      machine: sounds.machine,
      zoom: placement.zoom ?? 1,
      zoomOutOffset: sounds.zoomOutOffset,
      localZoom: placement.localZoom ?? 1,
      renderFFT: sounds.renderFFT,
      allLabels: sounds.allLabels,
      sampleRate: sounds.sampleRate,
      labelPlacements: sounds.labelPlacements,
      placementTypes: sounds.placementTypes,
      zoomOutLoading: placement.zoomOutLoading,
      volume: placement?.volume,
      labelsFilterData: placement.labelsFilterData,
    };
  }
);

const Placement = memo(
  ({ placement, navigate, selected, addDateActive, setQuery }: TProps) => {
    const dispatch: any = useDispatch();
    const { t } = useTranslation();
    const history = useNavigate();
    const {
      machine: { id },
      zoom,
      timezoneOffset,
      duration,
      zoomOutOffset,
      localZoom,
      renderFFT,
      allLabels,
      sampleRate,
      labelPlacements,
      placementTypes,
      zoomOutLoading,
      loadingBuffer,
      isPlaying,
      buffer: { amplifiedBuffer, bufferStart, bufferEnd, regions: regionsRaw },
      volume,
      labelsFilterData,
    } = useSelector(
      (state: AppState) => selector({ placement: placement.value, state }),
      isEqual
    );

    for (let region in regionsRaw) {
      for (let label in regionsRaw[region].labels) {
        for (let label2 in allLabels) {
          if (
            regionsRaw[region].labels[label].subcategory ===
            allLabels[label2].subcategory
          ) {
            regionsRaw[region].labels[label]["name"] = allLabels[label2].name;
          }
        }
      }
    }

    const labelPlacementsWithPlacementTypes = labelPlacements.map(
      (placement: any) => {
        return {
          label: placementTypes.find(
            (placementType: any) => placementType.id === placement.type
          )?.name,
          type: placement.type,
          value: placement.value,
        };
      }
    );

    const loadBufferSilentAction = useCallback(
      (windowStart: number, offset: number) => {
        dispatch(loadBufferSilent(windowStart, offset));
      },
      [dispatch]
    );

    const onChangeZoom = useCallback(
      (zoom: number) => dispatch(actions.setZoom(zoom, placement.value)),
      [dispatch, placement.value]
    );

    const onChangeDraw = useCallback(
      (draw: string) => dispatch(actions.setDraw(draw)),
      [dispatch]
    );

    const onChange = useCallback(
      (start: number) => {
        const newDate = moment.unix(start).format(DATE_FORMAT);
        navigate({
          timezoneOffset,
          date: newDate,
          time: moment.unix(start).format(TIME_FORMAT),
          duration,
        });
      },
      [navigate, timezoneOffset, duration]
    );

    const setPlaying = useCallback(() => {
      dispatch(actions.setPlaying(placement.value));
    }, [dispatch, placement.value]);

    useEffect(() => {
      const modifiedData = allLabels.filter(({ name }: any) => name[0] !== "$");

      if (modifiedData) {
        dispatch(
          soundsActions.setCheckedLabels(
            modifiedData.map((item: any) => ({
              name: item.name,
              subcategory: item.subcategory,
            }))
          )
        );
      }
    }, []);

    return (
      <Box
        style={{
          backgroundColor: selected ? "rgb(244, 221, 226)" : "transparent",
        }}
        my={2}
        p={2}
        id={"placement_" + placement.value}
      >
        {loadingBuffer || !amplifiedBuffer ? (
          <PlacementLoading
            loadingBuffer={loadingBuffer}
            binBuffer={amplifiedBuffer}
            placement={placement}
          />
        ) : (
          <Player
            key={`player-${placement.value}`}
            t={t}
            zoom={zoom}
            localZoom={localZoom}
            machine={id}
            placement={placement}
            labelPlacements={labelPlacementsWithPlacementTypes}
            buffer={amplifiedBuffer}
            sampleRate={sampleRate}
            bufferStart={bufferStart}
            bufferEnd={bufferEnd}
            windowStart={bufferStart}
            allLabels={allLabels}
            regions={regionsRaw}
            timezoneOffset={timezoneOffset}
            onChange={onChange}
            onChangeZoom={onChangeZoom}
            onChangeDraw={onChangeDraw}
            renderFFT={renderFFT}
            duration={duration}
            setPlaying={setPlaying}
            isPlaying={isPlaying}
            zoomOutOffset={zoomOutOffset}
            zoomOutLoading={zoomOutLoading}
            loadBufferSilent={loadBufferSilentAction}
            volume={volume}
            playerActions={soundsActions}
            history={history}
            addDateActive={addDateActive}
            labelsFilterData={labelsFilterData}
            setQuery={setQuery}
            showLabelFilter={true}
          />
        )}
      </Box>
    );
  },
  isEqual
);

export default Placement;
